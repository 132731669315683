<template>
  <div>
    <v-card color="">
      <v-card-title class="headline">Admin Home</v-card-title>
      <v-container>
      <v-row>
        <v-col>
          <h3>Events</h3>
          <v-text-field v-model="eventQuery" solo rounded clearable hide-details v-on:keyup.enter="searchEvent" @click:clear="eventQuery='';searchEvent()" @click:prepend="searchEvent" prepend-icon="fa-search" class="search-box" placeholder="Search Event" />
          <h4 class="mt-8">Actions</h4>
          <v-list>
            <v-list-item :to="{ name: 'eventmanagerRecent', params: {}}">
              <v-list-item-icon><v-icon>fa-calendar</v-icon> </v-list-item-icon>
              <v-list-item-title>Recently created</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'eventmanagerUpcoming', params: {}}">
              <v-list-item-icon><v-icon>fa-calendar</v-icon> </v-list-item-icon>
              <v-list-item-title>Upcoming</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'eventmanagerRenewals', params: {}}">
              <v-list-item-icon><v-icon>fa-calendar-alt</v-icon> </v-list-item-icon>
              <v-list-item-title>Up for renewal</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col>
          <h3>Users</h3>
          <v-text-field v-model="profileQuery" solo rounded clearable hide-details v-on:keyup.enter="searchProfile" @click:clear="profileQuery='';searchProfile()" @click:prepend="searchProfile" prepend-icon="fa-search" class="search-box" placeholder="Search Profile" />
        </v-col>
      </v-row>
      <v-divider/>
      <v-row>
        <v-col>
          <h3>Quick Tools</h3>
          <v-card color="grey lighten-4" class="ma-8">
            <v-card-subtitle>Remove Device Token</v-card-subtitle>
            <v-alert v-if="deleteProviderResult && deleteProviderResult.status == 'OK'" type="success" tile>Token deleted successfully.</v-alert>
            <v-alert v-if="deleteProviderResult && deleteProviderResult.status == 'ERROR'" type="error" tile>{{deleteProviderResult.msg}}</v-alert>
            <v-card-text>
              <v-text-field v-model="provider" label="Device Type" />
              <v-text-field v-model="providerId" label="Provider ID" />
              <v-btn color="accent" @click="deleteToken">Delete</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
        </v-col>
      </v-row>
      <v-divider/>
      <v-row>
        <v-col>
          <h3>Docs &amp; Tools</h3>
          <v-list>
            <v-list-item href="/resources/convert-steps" target="_blank">
              <v-list-item-icon><v-icon>fa-calculator</v-icon> </v-list-item-icon>
              <v-list-item-title>Step Convertor</v-list-item-title>
            </v-list-item>
            <v-list-item href="https://corporatefitness.helpscoutdocs.com/" target="_blank">
              <v-list-item-icon><v-icon>fa-books</v-icon> </v-list-item-icon>
              <v-list-item-title>Public FAQs</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col>
          <h3>Other Links</h3>
          <v-list>
            <v-list-item href="https://noctilucent.pipedrive.com/leads/inbox" target="_blank">
              <v-list-item-icon><v-icon>P</v-icon> </v-list-item-icon>
              <v-list-item-title>Pipedrive CRM</v-list-item-title>
            </v-list-item>
            <v-list-item href="https://secure.helpscout.net/inboxes/a4a7baf061f6d254/views/7984622" target="_blank">
              <v-list-item-icon><v-icon>fa-life-ring</v-icon> </v-list-item-icon>
              <v-list-item-title>Helpscout Inbox</v-list-item-title>
            </v-list-item>
            <v-list-item href="https://secure.helpscout.net/docs/6569a36e5469c2601676b28e/" target="_blank">
              <v-list-item-icon><v-icon>fa-books</v-icon> </v-list-item-icon>
              <v-list-item-title>Helpscout Docs - App</v-list-item-title>
            </v-list-item>
            <v-list-item href="https://secure.helpscout.net/docs/6568db0d73583e35d326770b/" target="_blank">
              <v-list-item-icon><v-icon>fa-books</v-icon> </v-list-item-icon>
              <v-list-item-title>Helpscout Docs - EventManager</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      </v-container>
    </v-card>
  </div>
</template>

<script>
// Todo: add swipe/scroll notification from https://codepen.io/surisdziugas/pen/LzXPwz

import { mapGetters } from "vuex";
import adminService from "@/services/adminService";

export default {
  name: "AdminHome",
  components: {
  },
  props: {
  },
  data() {
    return {
      eventQuery: null,
      profileQuery: null,

      provider: null,
      providerId: null,
      deleteProviderResult: null,
    };
  },
  async mounted() {
  },
  methods: {
    searchEvent() {
      this.$router.push({name: 'adminEvents', query: {search: this.eventQuery}});

    },

    searchProfile() {
      this.$router.push({name: 'adminProfile', params: {profileId: this.profileQuery}});
    },


    async deleteToken() {
      if (!this.provider || !this.providerId) {
        this.deleteProviderResult = { status: 'ERROR', msg: 'Please fill in all fields'};
      }
      
      this.deleteProviderResult = (await adminService.deleteToken(this.provider, this.providerId)).data;
    }

  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

